<template>
  <div class="loginPage pt-32 mb-48">
    <div class="layout-container px-0">
      <div class="md:w-3/4 lg:w-1/2 mx-auto">
        <h1 class="px-24 md:px-0">{{ model.title }}</h1>
        <!-- login -->
        <div>
          <div class="pb-24 text-xsmall EPiServerForms px-24 md:px-0">
            <div class="text-xsmall-medium sm:text-small-medium mb-12">{{ translation.loginTitle }}</div>
            <form @submit.prevent="submitLogin">
              <div class="mb-12">
                <input
                  v-model="username"
                  type="email"
                  autocomplete="email"
                  class="form-input"
                  :placeholder="translation.loginEmail"
                  :class="{
                    'has-error form-input--error': usernameError,
                  }"
                  @input="usernameError = ''"
                >
                <div v-if="usernameError" class="mt-6 text-xsmall-medium text-error">
                  {{ usernameError }}
                </div>
              </div>
              <div class="mb-12">
                <div class="relative">
                  <input
                    v-model="password"
                    :type="showPassword ? 'text': 'password'"
                    autocomplete="password"
                    :placeholder="translation.loginPassword"
                    class="form-input"
                    :class="{
                      'has-error form-input--error': passwordError,
                    }"
                    @input="passwordError = ''"
                  >
                  <div
                    class="absolute top-0 right-0 h-40 w-40 flex items-center justify-center sm:h-48 sm:w-48"
                    @click.stop="toggleShowPassword"
                  >
                    <img
                      :src="showPassword ? '/heroicons/outline/eye-slash.svg' : '/heroicons/outline/eye.svg'"
                      alt="close"
                      class="inline-block h-20 w-20"
                      height="20"
                      width="20"
                    >
                  </div>
                </div>
                <div v-if="passwordError" class="mt-6 text-xsmall-medium text-error">
                  {{ passwordError }}
                </div>
              </div>
              <GlobalsButton
                :text="translation.loginAction"
                :no-validate="true"
                :loading="loadLogin"
                class="mb-16 w-full"
              />
            </form>
            <div
              v-if="generalError"
              class="bg-error mb-8 rounded-md text-white p-12 flex items-start text-xsmall md:text-small-regular"
            >
              <ExclamationCircleIcon
                class="w-24 text-white mr-12 flex-shrink-0"
              />
              <div>
                <span class="mt-[5px] md:mt-2 block">{{ generalError }}</span>
                <div
                  v-if="additionalErrorMessage"
                  class="underline hover:no-underline inline cursor-pointer"
                  @click="resendVerifyEmailClick"
                >
                  {{ additionalErrorMessage }}
                </div>
              </div>
            </div>
            <button class="underline text-left" @click="showForgotPassword">
              {{ translation.loginForgot }}
            </button>
          </div>
          <EpiserverContentArea
            v-if="model.mainContent"
            v-epi-edit="'MainContent'"
            :model="model.mainContent"
            class="content-area-login-page full-width-content-area"
            :full-width="true"
          />
          <div class="pt-24 text-xsmall px-24 md:px-0">
            <div class="text-xsmall-medium sm:text-small-medium mb-12">{{ translation.signupTitleLong }}</div>
            <div class="bg-grey200 rounded-md p-16 sm:p-24">
              <div
                class="wysiwyg wysiwyg--small-text checkmarkUl"
                v-html="loginSettings.createAccountCta"
              />
              <button class="btn btn--primary w-full btn--sm h-40 sm:h-48" @click="showSignup">
                {{ translation.signupAction }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <ModalWrapper
        v-if="showVerifyEmailConfirmation"
        :closeable="true"
        @close="showVerifyEmailConfirmation = false"
      >
        <SignUpVerifyEmailInformation
          :is-in-modal="true"
        />
      </ModalWrapper>
    </transition>
  </div>
</template>

<script setup lang="ts">
import type { LoginPage } from '~/content-types';
import { useUiStore } from '~/store/ui';
import { useGlobalContentStore } from '~/store/globalContent';
import ExclamationCircleIcon from '@heroicons/vue/24/outline/esm/ExclamationCircleIcon';
import SignUpVerifyEmailInformation from '~/components/modals/SignUpVerifyEmailInformation.vue';
import ModalWrapper from '~/components/modals/ModalWrapper.vue';
import { ref } from 'vue';
import { type IICommandResultBase } from '~/api-types';

const { apiPost } = useApiFetch();
const globalContentStore = useGlobalContentStore();
const translation = globalContentStore.myPageResources;
const translation2 = globalContentStore.sharedResources;
const myAccountPage = globalContentStore.pageSettings.myAccountPage.url;
const uiStore = useUiStore();
const loginSettings = globalContentStore.loginSettings;
const marketSettings = globalContentStore.marketSettings;
const username = ref('');
const password = ref('');
const showPassword = ref(false);
const usernameError = ref('');
const passwordError = ref('');
const generalError = ref('');
const additionalErrorMessage = ref('');
const showVerifyEmailConfirmation = ref(false);
const returnUrl = ref('');
const loadLogin = ref(false);

defineProps<{
  model: LoginPage;
}>();

const route = useRoute();
returnUrl.value = route.query.returnUrl?.toString() || '/';

const toggleShowPassword = () => {
  showPassword.value = !showPassword.value;
};

const showForgotPassword = () => {
  uiStore.setShowLogin(true);
  uiStore.setLoginType('reminder');
};

const showSignup = () => {
  uiStore.setShowLogin(true);
  uiStore.setLoginType('signup');
};

const submitLogin = async() => {
  usernameError.value = '';
  passwordError.value = '';

  if (!username.value) {
    usernameError.value = translation2.emptyEmailError;
    return;
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(username.value.trim())) {
    usernameError.value = translation2.invalidEmail;
    return;
  }

  if (!password.value) {
    passwordError.value = translation2.emptyPasswordError;
    return;
  }

  loadLogin.value = true;

  const credentials = {
    countryCode: marketSettings.countryCode,
    email: username.value,
    password: password.value,
  };

  const result = await apiPost<IICommandResultBase>(
    'user/session',
    credentials
  );

  if (result && result.success) {
    loadLogin.value = false;
    uiStore.setShowLogin(false);
    uiStore.dataLayerPushOnLogin();
    await uiStore.checkLoggedIn();
    navigateTo(myAccountPage, { replace: true });
  } else {
    loadLogin.value = false;
    if (result.errorMessage == null && result.validationErrors.length > 0)
    {
      result.errorMessage = result.validationErrors[0].displayMessage;
    }
    generalError.value = result.errorMessage || 'error';

    if (result.userNotVerified) {
      additionalErrorMessage.value = translation2.resendVerificationEmail;
    }

    window.dataLayer?.push({
      event: 'Error',
      errorMessage: result.errorMessage || 'login error',
    });
  }
};

const resendVerifyEmailClick = async() => {
  const payload = {
    email: username.value,
    userId: '',
    returnUrl: '/',
  };
 
  const result = await apiPost<IICommandResultBase>(
    'user/resendverification',
    payload
  );

  if (result && result.success) {
    showVerifyEmailConfirmation.value = true;
    generalError.value = '';
    additionalErrorMessage.value = '';
  }
  if (result && !result.success) {
    window.dataLayer?.push({
      event: 'Error',
      errorMessage: 'Resend verification email error',
    });
  }
};
</script>

<style scoped lang="postcss">
.loginPage {
  min-height: calc(var(--vh, 1vh) * 100);
}
</style>

<style>
.content-area-login-page {
  .layout-container {
    @apply px-24 mb-24 md:mb-32 md:px-0;
    .wysiwyg--block {
      .text-heading-xl {
        margin-bottom: 0.8rem;
      }
      p {
        @apply w-full;
      }
    }

    .swiper-customer-service-cards {
      @apply !px-0;

      .swiper-wrapper {
        @apply grid-cols-2 gap-12 grid;

        .swiper-slide {
          @apply !mb-0;
        }

        .item {
          @apply pt-48 sm:pt-56 text-center sm:p-16;
        }

        .icon {
          @apply top-16 left-1/2 transform -translate-x-1/2;
        }
      }
    }

    .swiper-mobile {
      @apply !px-24 sm:!px-0;
      .swiper-wrapper {
        @apply flex gap-0 sm:grid-cols-2 sm:gap-12 sm:grid;
      }
    }

    &.customer-service-card-block.use-slider {
      @apply !px-0 sm:!px-24 md:!px-0;
    }
  }
}
</style>
